import React, { FunctionComponent, Fragment } from 'react';
import { Badge } from 'antd';
import { blue, green, purple, orange, grey } from '@ant-design/colors';

export const networkColors = [
    blue.primary,
    green.primary,
    orange.primary,
    purple.primary,
    grey.primary
];

const getNetworkColor = (network: string) => {
    switch (network) {
        case 'ropsten':
            return networkColors[0];
        case 'rinkeby':
            return networkColors[1];
        case 'kovan':
            return networkColors[2];
        case 'goerli':
            return networkColors[3];
        default:
            return networkColors[4];
    }
};

interface Props {
    type: string;
}

const Network: FunctionComponent<Props> = ({ type }) => (
    <Fragment>
        <Badge color={getNetworkColor(type)} text={type.charAt(0).toUpperCase() + type.slice(1)} />
    </Fragment>
);

export default Network;
