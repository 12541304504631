import { ethers } from 'ethers';
import axios, { AxiosResponse } from 'axios';
import Bottleneck from 'bottleneck';
import { FAUCET_ADDRESS, FAUCET_NETWORK, FAUCET_NETWORKS, FAUCET_API } from '../data/faucet';
import { Transaction, NetworkTransaction } from '../types/transaction';
import { useDatabase } from '../hooks';

const ETHERSCAN_API_KEY = `2IGRKGETAMAZ3HVCEYM6JSWTRZUI7MXVSP`;

const limiter = new Bottleneck({
    minTime: 250,
    maxConcurrent: 1
});

type Database = ReturnType<typeof useDatabase>;

type BalanceMap = { [key in FAUCET_NETWORK]?: string };

interface APIResponseData {
    success: boolean;
    result: string;
}

type APIResponse = AxiosResponse<APIResponseData> | void;

export const getAPIStatus = async () => {
    let success = false;
    const url = `${FAUCET_API}/ping`;
    const response: APIResponse = await limiter
        .schedule(() => axios.get(url))
        .catch(err => {
            console.error(err);
        });
    if (response && response.data && response.data.success) {
        success = true;
    }
    return success;
};

export const getBalances = async () => {
    const balances: BalanceMap = {};
    await Promise.all(
        FAUCET_NETWORKS.map(async faucetNetwork => {
            const provider = ethers.getDefaultProvider(faucetNetwork);
            const balance = await provider.getBalance(FAUCET_ADDRESS);
            balances[faucetNetwork] = balance.toString();
        })
    );
    return balances;
};

export const getNetworkTransactions = async (
    database: Database,
    network: string,
    startblock: string = '0'
) => {
    let txs: Transaction[] = [];
    const request = await limiter.schedule(() =>
        axios.get(`https://api-${network}.etherscan.io/api`, {
            params: {
                module: 'account',
                action: 'txlist',
                address: FAUCET_ADDRESS,
                sort: 'asc',
                apikey: ETHERSCAN_API_KEY,
                startblock
            }
        })
    );
    const { result } = request.data;
    txs = txs.concat(result);
    if (result.length > 0) {
        const highestBlock = result[result.length - 1].blockNumber;
        await database.set('blocks', `${network}:height`, highestBlock);
        if (result.length >= 10000) {
            txs = txs.concat(await getNetworkTransactions(database, network, highestBlock));
        }
    }
    const txResult: NetworkTransaction[] = txs.map(transaction => {
        const extendedTx: NetworkTransaction = {
            ...transaction,
            network
        };
        return extendedTx;
    });
    return txResult;
};

export const getTransactions = async (database: Database) => {
    let txs: NetworkTransaction[] = [];
    await Promise.all(
        FAUCET_NETWORKS.map(async faucetNetwork => {
            const blockHeight = await database.get('blocks', `${faucetNetwork}:height`);
            const networkTransactions = await getNetworkTransactions(
                database,
                faucetNetwork,
                blockHeight
            );
            txs = txs.concat(networkTransactions);
        })
    );
    return txs;
};
