import React, { FunctionComponent } from 'react';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

interface Props {
    loading: boolean;
    action(): void;
}

const ReloadButton: FunctionComponent<Props> = ({ loading, action }: Props) => {
    return (
        <Button
            style={{ position: 'absolute', right: 0, top: 0 }}
            shape="circle"
            icon={<ReloadOutlined />}
            loading={loading}
            disabled={loading}
            onClick={action}
        />
    );
};

export default ReloadButton;
