import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(localizedFormat);

dayjs.extend(updateLocale);

dayjs.extend(relativeTime, {
    thresholds: [
        { l: 's', r: 4 },
        { l: 'ss', r: 44, d: 'second' },
        { l: 'm', r: 89 },
        { l: 'mm', r: 44, d: 'minute' },
        { l: 'h', r: 89 },
        { l: 'hh', r: 21, d: 'hour' },
        { l: 'd', r: 35 },
        { l: 'dd', r: 25, d: 'day' },
        { l: 'M', r: 45 },
        { l: 'MM', r: 10, d: 'month' },
        { l: 'y' },
        { l: 'yy', d: 'year' }
    ]
});

dayjs.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        ss: '%d seconds',
        m: 'a minute',
        mm: '%d minutes',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years'
    }
});

export default dayjs;
