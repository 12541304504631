import { openDB, IDBPDatabase } from 'idb';

interface ItemList {
    [key: string]: string;
}

export const useDatabase = () => {
    let loadedDB: IDBPDatabase<unknown>;

    const getDB = async () => {
        if (loadedDB) {
            return loadedDB;
        } else {
            const result = await openDB('faucet-dashboard', 2, {
                upgrade(db) {
                    db.createObjectStore(`txs`);
                    db.createObjectStore(`blocks`);
                }
            });
            loadedDB = result;
            return result;
        }
    };

    const get = async (storeName: string, key: string) => {
        const db = await getDB();
        const store = db.transaction(storeName).objectStore(storeName);
        const value = await store.get(key);
        return value;
    };

    const set = async (storeName: string, key: string, value: string) => {
        const db = await getDB();
        const tx = db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        await store.put(value, key);
        await tx.done;
    };

    const putList = async (storeName: string, items: ItemList) => {
        const db = await getDB();
        const tx = db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        await Promise.all(Object.keys(items).map(key => store.put(items[key], key)));
        await tx.done;
    };

    const deleteKey = async (storeName: string, key: string) => {
        const db = await getDB();
        const tx = db.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);
        await store.delete(key);
        await tx.done;
    };

    const keys = async (storeName: string) => {
        const db = await getDB();
        const store = db.transaction(storeName).objectStore(storeName);
        const result = await store.getAllKeys();
        return result;
    };

    const getAll = async (storeName: string) => {
        const db = await getDB();
        const tx = db.transaction(storeName);
        const store = tx.objectStore(storeName);
        const result = await store.getAll();
        return result;
    };

    const count = async (storeName: string) => {
        const db = await getDB();
        const tx = db.transaction(storeName);
        const store = tx.objectStore(storeName);
        const result = await store.count();
        return result;
    };

    if (typeof window !== 'undefined') {
        return {
            get,
            set,
            putList,
            delete: deleteKey,
            keys,
            getAll,
            count
        };
    } else {
        return {
            get: async () => undefined,
            set: async () => undefined,
            putList: async () => undefined,
            delete: async () => undefined,
            keys: async () => undefined,
            getAll: async () => [],
            count: async () => 0
        };
    }
};
