import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import { Menu } from 'antd';
import { HomeOutlined, DatabaseOutlined } from '@ant-design/icons';

const Header: FunctionComponent = () => {
    const selectedKey = typeof window === 'undefined' ? '/' : window.location.pathname;
    return (
        <Menu
            selectedKeys={[selectedKey, selectedKey.replace(/\//g, '')]}
            mode="horizontal"
            theme="dark"
        >
            <Menu.Item key="/">
                <Link to="/">
                    <HomeOutlined />
                    Overview
                </Link>
            </Menu.Item>
            <Menu.Item key="transactions">
                <Link to="/transactions">
                    <DatabaseOutlined />
                    Transactions
                </Link>
            </Menu.Item>
        </Menu>
    );
};

export default Header;
