import { graphql, useStaticQuery } from 'gatsby';

interface Query {
    site: {
        siteMetadata: {
            title: string;
            description: string;
            themeColor: string;
        };
    };
}

export const useSiteMetadata = () => {
    const query: Query = useStaticQuery(
        graphql`
            query Site {
                site {
                    siteMetadata {
                        title
                        description
                        themeColor
                    }
                }
            }
        `
    );

    return query.site.siteMetadata;
};
