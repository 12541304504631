import { useState } from 'react';
import { useDispatch, useSelector, useDatabase, useTime } from '../hooks';
import { FaucetAPIStatus, updateStatus, updateBalances, updateTransactions } from '../store/faucet';
import { getAPIStatus, getBalances, getTransactions } from '../utils/faucet';
import { NetworkTransaction } from '../types/transaction';

const refreshTimeout = 30 * 1000;

interface TransactionMap {
    [key: string]: string;
}

export const useData = () => {
    const dispatch = useDispatch();
    const database = useDatabase();
    const balances = useSelector(state => state.faucet.balances);
    const balancesUpdated = useSelector(state => state.faucet.balancesUpdated);
    const totalTransactions = useSelector(state => state.faucet.totalTransactions);
    const status = useSelector(state => state.faucet.status);
    const statusUpdated = useSelector(state => state.faucet.statusUpdated);
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([] as NetworkTransaction[]);
    const [parsing, setParsing] = useState(false);

    const updateData = () => {
        setLoading(true);
        getAPIStatus().then(apiStatus => {
            if (apiStatus) {
                dispatch(updateStatus(FaucetAPIStatus.ONLINE));
            } else {
                dispatch(updateStatus(FaucetAPIStatus.OFFLINE));
            }
            getBalances().then(resultBalances => {
                dispatch(updateBalances(resultBalances));
                getTransactions(database).then(resultTransactions => {
                    const txMap: TransactionMap = {};
                    resultTransactions.map(tx => {
                        txMap[`${tx.network}:${tx.hash}`] = JSON.stringify(tx);
                    });
                    database.putList('txs', txMap).then(() => {
                        database.count('txs').then(txCount => {
                            dispatch(updateTransactions(txCount));
                            setLoading(false);
                        });
                    });
                });
            });
        });
    };

    if (!parsing && !loading && Date.now() - balancesUpdated > refreshTimeout) {
        updateData();
    }

    if (transactions.length !== totalTransactions && !parsing && !loading) {
        setParsing(true);
        database.getAll('txs').then(items => {
            setTransactions(items.map(item => JSON.parse(item)));
            setParsing(false);
        });
    }

    useTime(refreshTimeout);

    return {
        loading: loading || parsing,
        parsing: (loading || parsing) && transactions.length !== totalTransactions,
        status,
        balances,
        transactions,
        statusUpdated,
        updateData
    };
};
