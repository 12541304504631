import React, { FunctionComponent } from 'react';
import ExternalLink from '../ExternalLink';
import { Avatar, Menu } from 'antd';
import { GithubOutlined } from '@ant-design/icons';
import { repository } from '../../../package.json';

const socials = [
    {
        color: '#333',
        icon: <GithubOutlined />,
        title: 'GitHub',
        url: repository.replace(/\.git$/, '')
    }
];

const Footer: FunctionComponent = () => (
    <Menu
        selectedKeys={[]}
        mode="horizontal"
        theme="dark"
        style={{ paddingTop: '10px', paddingBottom: '10px', textAlign: 'center' }}
    >
        {socials.map((item, index) => (
            <Menu.Item key={index}>
                <ExternalLink to={item.url}>
                    <Avatar style={{ backgroundColor: item.color }} icon={item.icon} />
                </ExternalLink>
            </Menu.Item>
        ))}
    </Menu>
);

export default Footer;
