import React, { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { useSiteMetadata } from '../../hooks';

const MetaData: FunctionComponent = () => {
    const siteMetadata = useSiteMetadata();

    return (
        <Helmet htmlAttributes={{ lang: 'en' }}>
            <title>{siteMetadata.title}</title>
            <meta name="apple-mobile-web-app-title" content={siteMetadata.title} />
            <meta name="description" content={siteMetadata.description} />
            <meta name="theme-color" content={siteMetadata.themeColor} />
            <meta name="mobile-web-app-capable" content="yes" />
        </Helmet>
    );
};

export default MetaData;
