import { useState, useEffect } from 'react';

const getTime = () => Date.now();

export const useTime = (refreshCycle: number = 100) => {
    const [now, setNow] = useState(getTime());

    useEffect(() => {
        const interval = setInterval(() => setNow(getTime()), refreshCycle);

        return () => clearInterval(interval);
    }, [refreshCycle, setInterval, clearInterval, setNow, getTime]);

    return now;
};
